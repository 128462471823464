.banner {
  width: 100%;
  padding-top: 32px;
  text-align: center;
  // contents_itemのmarginTop(6dvh)からmargin-bottom分を引いている
  // 6dvh - 3.6dvh = 2.4dvh(ビューポートに依存した割合)
  margin-bottom: -3.6dvh;
  scroll-snap-align: start;
  scroll-snap-stop: always;
  scroll-margin-top: 77px;
  & > a > img {
    width: 100%;
    max-width: 640px; // .contents_itemの最大幅
  }
}
